import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { PieChart, LineChart, Line, BarChart, Bar, Pie, ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Cell } from 'recharts';
import { ArrowRight, Truck, Database, Cpu, BarChart2, MapPin, Users, Clock, Shovel, Mail, Phone, ChevronDown, Github, Linkedin, Twitter, Menu, X, ChevronRight } from 'lucide-react';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "./ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";

// Initial mock data
const initialProductionData = [
    { name: 'Jan', production: 1200, efficiency: 75, equipment_utilization: 82 },
    { name: 'Feb', production: 1350, efficiency: 78, equipment_utilization: 85 },
    { name: 'Mar', production: 1500, efficiency: 80, equipment_utilization: 88 },
    { name: 'Apr', production: 1650, efficiency: 82, equipment_utilization: 90 },
    { name: 'May', production: 1800, efficiency: 85, equipment_utilization: 92 },
    { name: 'Jun', production: 1950, efficiency: 87, equipment_utilization: 94 },
    { name: 'Jul', production: 2100, efficiency: 90, equipment_utilization: 95 },
];

const initialEquipmentUtilization = [
    { name: 'Trucks', value: 85 },
    { name: 'Shovels', value: 90 },
    { name: 'Loaders', value: 82 },
    { name: 'Drills', value: 78 },
];

const initialMiningLocations = [
    { name: 'Pit A', tons: 5200, efficiency: 88 },
    { name: 'Pit B', tons: 4800, efficiency: 85 },
    { name: 'Pit C', tons: 5000, efficiency: 87 },
    { name: 'Pit D', tons: 5400, efficiency: 90 },
];

const initialOperatorPerformance = [
    { operator: 'John', loads: 125, efficiency: 92 },
    { operator: 'Sarah', loads: 130, efficiency: 95 },
    { operator: 'Mike', loads: 120, efficiency: 90 },
    { operator: 'Emma', loads: 135, efficiency: 97 },
];

const AnimatedIcon = ({ icon: Icon, delay = 0 }) => (
    <motion.div
        initial={{ y: 0 }}
        animate={{ y: [-10, 10] }}
        transition={{ repeat: Infinity, repeatType: "reverse", duration: 2, delay }}
    >
        <Icon className="w-12 h-12 text-blue-400" />
    </motion.div>
);

const FeatureCard = ({ icon: Icon, title, description }) => (
    <motion.div
        whileHover={{ scale: 1.05 }}
        className="bg-gradient-to-br from-gray-800 to-gray-900 p-6 rounded-xl shadow-xl border border-gray-700 group transition-all duration-300"
    >
        <AnimatedIcon icon={Icon} />
        <h3 className="text-xl font-semibold mb-2 text-blue-300 group-hover:text-blue-200 transition-colors duration-300">{title}</h3>
        <p className="text-gray-400 group-hover:text-gray-300 transition-colors duration-300">{description}</p>
    </motion.div>
);

const AnimatedBackground = () => (
    <div className="absolute inset-0 overflow-hidden z-0">
        <div className="absolute w-96 h-96 bg-blue-500 rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob"></div>
        <div className="absolute w-96 h-96 bg-purple-500 rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob animation-delay-2000 top-1/4 right-1/4"></div>
        <div className="absolute w-96 h-96 bg-indigo-500 rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob animation-delay-4000 bottom-1/4 left-1/4"></div>
    </div>
);

const InteractiveChart = React.memo(({ chartType, data, config }) => {
    const [hoveredData, setHoveredData] = useState(null);

    const handleMouseEnter = useCallback((data) => {
        setHoveredData(data);
    }, []);

    const handleMouseLeave = useCallback(() => {
        setHoveredData(null);
    }, []);

    const chartContent = useMemo(() => {
        switch (chartType) {
            case 'line':
                return (
                    <LineChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                        <XAxis dataKey={config.xAxis} stroke="#9CA3AF" />
                        <YAxis stroke="#9CA3AF" />
                        <Tooltip contentStyle={{ backgroundColor: '#1F2937', border: 'none', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }} />
                        <Legend />
                        {config.lines.map((line, index) => (
                            <Line
                                key={index}
                                type="monotone"
                                dataKey={line.dataKey}
                                stroke={line.color}
                                strokeWidth={3}
                                dot={{ stroke: line.color, strokeWidth: 2, r: 4 }}
                                activeDot={{ r: 8, onMouseEnter: () => handleMouseEnter(line), onMouseLeave: handleMouseLeave }}
                            />
                        ))}
                    </LineChart>
                );
            case 'bar':
                return (
                    <BarChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                        <XAxis dataKey={config.xAxis} stroke="#9CA3AF" />
                        <YAxis stroke="#9CA3AF" />
                        <Tooltip contentStyle={{ backgroundColor: '#1F2937', border: 'none', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }} />
                        <Legend />
                        {config.bars.map((bar, index) => (
                            <Bar
                                key={index}
                                dataKey={bar.dataKey}
                                fill={bar.color}
                                onMouseEnter={() => handleMouseEnter(bar)}
                                onMouseLeave={handleMouseLeave}
                            />
                        ))}
                    </BarChart>
                );
            case 'pie':
                return (
                    <PieChart>
                        <Pie
                            data={data}
                            dataKey={config.dataKey}
                            nameKey={config.nameKey}
                            cx="50%"
                            cy="50%"
                            outerRadius={80}
                            fill="#8884d8"
                            label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                            labelLine={false}
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={config.colors[index % config.colors.length]} />
                            ))}
                        </Pie>
                        <Tooltip contentStyle={{ backgroundColor: '#1F2937', border: 'none', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }} />
                        <Legend formatter={(value, entry, index) => <span style={{ color: 'white' }}>{value}</span>} />
                    </PieChart>
                );
            case 'scatter':
                return (
                    <ScatterChart>
                        <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                        <XAxis dataKey={config.xAxis} name={config.xAxisName} stroke="#9CA3AF" />
                        <YAxis dataKey={config.yAxis} name={config.yAxisName} stroke="#9CA3AF" />
                        <Tooltip cursor={{ strokeDasharray: '3 3' }} contentStyle={{ backgroundColor: '#1F2937', border: 'none', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }} />
                        <Legend />
                        <Scatter name={config.name} data={data} fill={config.color} onMouseEnter={() => handleMouseEnter(config)} onMouseLeave={handleMouseLeave} />
                    </ScatterChart>
                );
            default:
                return null;
        }
    }, [chartType, data, config, handleMouseEnter, handleMouseLeave]);

    return (
        <div className="relative">
            <ResponsiveContainer width="100%" height={300}>
                {chartContent}
            </ResponsiveContainer>
            <AnimatePresence>
                {hoveredData && (
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        className="absolute top-0 left-0 bg-gray-800 p-2 rounded-md shadow-md"
                    >
                        <p className="text-sm text-blue-300">{hoveredData.name || hoveredData.dataKey}: Hover for more info</p>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
});

const TechBadge = ({ icon: Icon, label }) => (
    <motion.div
        whileHover={{ scale: 1.1 }}
        className="flex items-center bg-gradient-to-r from-blue-600 to-blue-800 rounded-full px-4 py-2 text-sm text-white shadow-lg transition-all duration-300"
    >
        <Icon className="w-5 h-5 mr-2" />
        {label}
    </motion.div>
);

const PITLogo = () => (
    <svg width="40" height="40" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="100" height="100" rx="20" fill="#3B82F6" />
        <path d="M50 10 L85 30 L85 70 L50 90 L15 70 L15 30 Z" stroke="white" strokeWidth="4" fill="none" />
        <path d="M50 30 L70 40 L70 60 L50 70 L30 60 L30 40 Z" stroke="#10B981" strokeWidth="4" fill="none" />
        <path d="M50 40 L60 45 L60 55 L50 60 L40 55 L40 45 Z" fill="#10B981" />
        <line x1="50" y1="10" x2="50" y2="0" stroke="white" strokeWidth="4" />
        <line x1="50" y1="90" x2="50" y2="100" stroke="white" strokeWidth="4" />
        <line x1="85" y1="30" x2="100" y2="25" stroke="white" strokeWidth="4" />
        <line x1="85" y1="70" x2="100" y2="75" stroke="white" strokeWidth="4" />
        <line x1="15" y1="30" x2="0" y2="25" stroke="white" strokeWidth="4" />
        <line x1="15" y1="70" x2="0" y2="75" stroke="white" strokeWidth="4" />
    </svg>
);

const ColorLegend = () => {
    const legendItems = [
        { color: '#00FF00', label: 'Green - Optimal Performance' },
        { color: '#FFFF00', label: 'Yellow - Requires Attention' },
        { color: '#FF5555', label: 'Red - Critical Issues' },
    ];

    return (
        <div className="bg-gray-800 p-4 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold mb-4 text-white">Color Legend</h3>
            <ul className="space-y-2">
                {legendItems.map((item, index) => (
                    <li key={index} className="flex items-center">
                        <div
                            className="w-6 h-6 rounded-full mr-3"
                            style={{ backgroundColor: item.color }}
                        ></div>
                        <span className="text-white">{item.label}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

const MobileMenu = ({ isOpen, toggleMenu }) => (
    <motion.div
        initial={{ x: "100%" }}
        animate={{ x: isOpen ? 0 : "100%" }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
        className="fixed top-0 right-0 h-full w-64 bg-gray-900 z-50 p-4"
    >
        <button onClick={toggleMenu} className="absolute top-4 right-4 text-white">
            <X size={24} />
        </button>
        <nav className="menu">
            <ul className="space-y-4">
                {['Features', 'Analytics', 'About'].map((item) => (
                    <li key={item}>
                        <a
                            href={`#${item.toLowerCase()}`}
                            className="text-white hover:text-blue-400 transition-colors duration-300"
                            onClick={toggleMenu}
                        >
                            {item}
                        </a>
                    </li>
                ))}
            </ul>
        </nav>
    </motion.div>
);

const ExploreDialog = () => (
    <Dialog>
        <DialogTrigger asChild>
            <Button className="bg-gradient-to-r from-blue-500 to-purple-500 text-white px-8 py-4 rounded-full font-semibold hover:from-blue-600 hover:to-purple-600 transition-all duration-300 flex items-center shadow-lg group">
                Explore pIT.Software
                <ArrowRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[700px] bg-gradient-to-br from-gray-900 to-gray-800 text-white border-none shadow-2xl">
            <DialogHeader className="relative">
                <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-blue-500 to-purple-500"></div>
                <DialogTitle className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
                    Explore pIT.Software
                </DialogTitle>
                <DialogDescription className="text-gray-300">
                    Dive into the powerful features of our cutting-edge mining fleet management system.
                </DialogDescription>
            </DialogHeader>
            <Tabs defaultValue="overview" className="w-full mt-6">
                <TabsList className="grid w-full grid-cols-3 bg-gray-700 rounded-lg p-1">
                    {["overview", "features", "demo"].map((tab) => (
                        <TabsTrigger 
                            key={tab} 
                            value={tab}
                            className="py-2 text-sm font-medium transition-all data-[state=active]:bg-gray-600 data-[state=active]:text-white rounded-md"
                        >
                            {tab.charAt(0).toUpperCase() + tab.slice(1)}
                        </TabsTrigger>
                    ))}
                </TabsList>
                <TabsContent value="overview">
                    <Card className="bg-gray-800 border-none shadow-lg overflow-hidden">
                        <CardHeader className="bg-gradient-to-r from-blue-500/10 to-purple-500/10 border-b border-gray-700">
                            <CardTitle className="text-2xl font-bold text-blue-400">pIT.Software Overview</CardTitle>
                            <CardDescription className="text-gray-300">
                                Revolutionize your mining operations with our advanced fleet management system.
                            </CardDescription>
                        </CardHeader>
                        <CardContent className="mt-4 space-y-4">
                            <p className="text-gray-300">pIT.Software offers a comprehensive solution for managing and optimizing your mining fleet. Our system integrates:</p>
                            <ul className="list-disc list-inside space-y-2 text-gray-300">
                                <li>Real-time data analytics</li>
                                <li>AI-driven insights</li>
                                <li>User-friendly interfaces</li>
                            </ul>
                            <p className="text-gray-300">Boost productivity and reduce operational costs with our state-of-the-art technology.</p>
                        </CardContent>
                    </Card>
                </TabsContent>
                <TabsContent value="features">
                    <Card className="bg-gray-800 border-none shadow-lg overflow-hidden">
                        <CardHeader className="bg-gradient-to-r from-blue-500/10 to-purple-500/10 border-b border-gray-700">
                            <CardTitle className="text-2xl font-bold text-blue-400">Key Features</CardTitle>
                            <CardDescription className="text-gray-300">
                                Discover the powerful features that set pIT.Software apart.
                            </CardDescription>
                        </CardHeader>
                        <CardContent className="mt-4">
                            <ul className="space-y-4">
                                {[
                                    "Real-time fleet tracking and optimization",
                                    "AI-powered predictive maintenance",
                                    "Advanced data analytics and reporting",
                                    "Intelligent crew management",
                                    "Seamless integration with existing systems"
                                ].map((feature, index) => (
                                    <li key={index} className="flex items-center space-x-3 text-gray-300">
                                        <div className="flex-shrink-0 w-8 h-8 bg-gradient-to-br from-blue-500 to-purple-500 rounded-full flex items-center justify-center">
                                            <ChevronRight className="h-5 w-5 text-white" />
                                        </div>
                                        <span>{feature}</span>
                                    </li>
                                ))}
                            </ul>
                        </CardContent>
                    </Card>
                </TabsContent>
                <TabsContent value="demo">
                    <Card className="bg-gray-800 border-none shadow-lg overflow-hidden">
                        <CardHeader className="bg-gradient-to-r from-blue-500/10 to-purple-500/10 border-b border-gray-700">
                            <CardTitle className="text-2xl font-bold text-blue-400">Live Demo</CardTitle>
                            <CardDescription className="text-gray-300">
                                Experience pIT.Software in action with our interactive demo.
                            </CardDescription>
                        </CardHeader>
                        <CardContent className="mt-4">
                            <form className="space-y-4">
                                {[
                                    { label: "Name", id: "name", placeholder: "Name", type: "text" },
                                    { label: "Email", id: "email", placeholder: "Email", type: "email" },
                                    { label: "Company", id: "company", placeholder: "Company", type: "text" }
                                ].map((field) => (
                                    <div key={field.id} className="space-y-2">
                                        <Label htmlFor={field.id} className="text-gray-300">{field.label}</Label>
                                        <Input 
                                            id={field.id} 
                                            placeholder={field.placeholder} 
                                            type={field.type}
                                            className="bg-gray-700 border-gray-600 text-white placeholder-gray-400 focus:border-blue-500 focus:ring-blue-500"
                                        />
                                    </div>
                                ))}
                                <Button className="w-full bg-gradient-to-r from-blue-500 to-purple-500 text-white hover:from-blue-600 hover:to-purple-600 transition-all duration-300">
                                    Start Demo
                                </Button>
                            </form>
                        </CardContent>
                    </Card>
                </TabsContent>
            </Tabs>
        </DialogContent>
    </Dialog>
);

export default function PITSoftwareLandingPage() {
    const [scrollY, setScrollY] = useState(0);
    const [activeChart, setActiveChart] = useState('production');
    const [showScrollTop, setShowScrollTop] = useState(false);
    const [productionData, setProductionData] = useState(initialProductionData);
    const [equipmentUtilization, setEquipmentUtilization] = useState(initialEquipmentUtilization);
    const [miningLocations, setMiningLocations] = useState(initialMiningLocations);
    const [operatorPerformance, setOperatorPerformance] = useState(initialOperatorPerformance);
    const [isMobile, setIsMobile] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const updateData = useCallback(() => {
        setProductionData(prevData =>
            prevData.map(item => ({
                ...item,
                production: item.production + Math.floor(Math.random() * 100 - 50),
                efficiency: Math.min(100, Math.max(0, item.efficiency + Math.floor(Math.random() * 6 - 3))),
                equipment_utilization: Math.min(100, Math.max(0, item.equipment_utilization + Math.floor(Math.random() * 4 - 2)))
            }))
        );

        setEquipmentUtilization(prevData =>
            prevData.map(item => ({
                ...item,
                value: Math.min(100, Math.max(0, item.value + Math.floor(Math.random() * 6 - 3)))
            }))
        );

        setMiningLocations(prevData =>
            prevData.map(item => ({
                ...item,
                tons: item.tons + Math.floor(Math.random() * 200 - 100),
                efficiency: Math.min(100, Math.max(0, item.efficiency + Math.floor(Math.random() * 4 - 2)))
            }))
        );

        setOperatorPerformance(prevData =>
            prevData.map(item => ({
                ...item,
                loads: item.loads + Math.floor(Math.random() * 6 - 3),
                efficiency: Math.min(100, Math.max(0, item.efficiency + Math.floor(Math.random() * 4 - 2)))
            }))
        );
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
            setShowScrollTop(window.scrollY > 500);
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        const intervalId = setInterval(updateData, 5000); // Update every 5 seconds for a smoother experience

        handleResize(); // Initial check

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
            clearInterval(intervalId);
        };
    }, [updateData]);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    return (
        <div className="bg-gray-900 min-h-screen text-white relative">
            <AnimatedBackground />

            {/* Header */}
            <motion.header
                initial={{ y: -100 }}
                animate={{ y: 0 }}
                transition={{ type: 'spring', stiffness: 100 }}
                className="bg-gray-900 bg-opacity-90 p-4 fixed w-full z-50 transition-all duration-300 backdrop-blur-md"
                style={{
                    backgroundColor: `rgba(17, 24, 39, ${scrollY / 500})`,
                    boxShadow: `0 ${scrollY > 50 ? '4px 6px -1px' : '0 0 0'} rgba(0, 0, 0, 0.1), 0 ${scrollY > 50 ? '2px 4px -1px' : '0 0 0'} rgba(0, 0, 0, 0.06)`,
                    zIndex: 1000
                }}
            >
                <div className="container mx-auto flex justify-between items-center">
                    <div className="flex items-center space-x-2">
                        <PITLogo />
                        <h1 className="text-2xl font-bold text-blue-400">pIT.Software</h1>
                    </div>
                    {isMobile ? (
                        <button onClick={toggleMenu} className="text-white">
                            <Menu size={24} />
                        </button>
                    ) : (
                        <nav>
                            <ul className="flex space-x-6">
                                {['Features', 'Analytics', 'About'].map((item) => (
                                    <li key={item}>
                                        <a href={`#${item.toLowerCase()}`} className="hover:text-blue-400 transition-colors duration-300 relative group">
                                            {item}
                                            <span className="absolute left-0 bottom-0 w-full h-0.5 bg-blue-400 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    )}
                </div>
            </motion.header>

            <AnimatePresence>
                {isMobile && <MobileMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} />}
            </AnimatePresence>

            {/* Hero Section */}
            <section className="pt-32 pb-20 px-4 bg-gradient-to-br from-gray-900 via-blue-900 to-gray-900 min-h-screen flex items-center relative overflow-hidden">
                <div className="container mx-auto relative z-10">
                    <div className="flex flex-col md:flex-row items-center">
                        <motion.div
                            initial={{ opacity: 0, x: -50 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.8 }}
                            className="md:w-1/2 mb-12 md:mb-0"
                        >
                            <h2 className="hero-text text-5xl md:text-6xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-400">
                                Revolutionize Your Fleet Management
                            </h2>
                            <p className="text-xl mb-8 text-gray-300">
                                Harness the power of AI and real-time data analytics with pIT.Software's advanced mining fleet management system
                            </p>
                            <ExploreDialog />
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, x: 50 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.8, delay: 0.2 }}
                            className="md:w-1/2"
                        >
                            <div className="bg-gray-800 p-6 rounded-2xl shadow-2xl border border-gray-700 transform hover:scale-105 transition-transform duration-300">
                                <h3 className="text-2xl font-semibold mb-4 text-blue-300">Real-time Production Overview</h3>
                                <InteractiveChart
                                    chartType="line"
                                    data={productionData}
                                    config={{
                                        xAxis: 'name',
                                        lines: [
                                            { dataKey: 'production', color: '#3B82F6', name: 'Production (tons)' },
                                            { dataKey: 'efficiency', color: '#10B981', name: 'Efficiency (%)' },
                                            { dataKey: 'equipment_utilization', color: '#F59E0B', name: 'Equipment Utilization (%)' }
                                        ]
                                    }}
                                />
                            </div>
                        </motion.div>
                    </div>
                </div>
            </section>

            {/* Features Section */}
            <section id="features" className="py-20 px-4 bg-gray-800 relative overflow-hidden">
                <AnimatedBackground />
                <div className="container mx-auto relative z-10">
                    <motion.h2
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        className="text-4xl font-bold text-center mb-16 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-400"
                    >
                        Key Features
                    </motion.h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
                        <FeatureCard
                            icon={Truck}
                            title="Real-time Fleet Optimization"
                            description="Maximize efficiency with AI-driven tracking and intelligent route planning for your entire mining fleet."
                        />
                        <FeatureCard
                            icon={Database}
                            title="Advanced Data Integration"
                            description="Seamlessly integrate with existing mine management software and process data from multiple sources in real-time."
                        />
                        <FeatureCard
                            icon={Cpu}
                            title="AI-Powered Predictive Insights"
                            description="Leverage advanced AI algorithms to predict maintenance needs and identify optimization opportunities."
                        />
                        <FeatureCard
                            icon={BarChart2}
                            title="Dynamic Real-time Reporting"
                            description="Access live visualizations and analytics for crucial decision-making and performance evaluation."
                        />
                        <FeatureCard
                            icon={MapPin}
                            title="Advanced Geospatial Analysis"
                            description="Utilize cutting-edge mapping and location-based analytics to optimize dig and dump locations in real-time."
                        />
                        <FeatureCard
                            icon={Users}
                            title="Intelligent Crew Management"
                            description="Efficiently manage shifts, operators, and equipment assignments with AI-driven recommendations."
                        />
                    </div>
                </div>
            </section>

            {/* Analytics Section */}
            <section id="analytics" className="py-20 px-4 bg-gradient-to-br from-gray-900 via-blue-900 to-gray-900 relative overflow-hidden">
                <AnimatedBackground />
                <div className="container mx-auto relative z-10">
                    <motion.h2
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        className="text-4xl font-bold text-center mb-16 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-400"
                    >
                        Advanced Real-time Analytics
                    </motion.h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
                        <motion.div
                            initial={{ opacity: 0, x: -20 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.8 }}
                            className="bg-gray-800 p-6 rounded-2xl shadow-2xl border border-gray-700"
                        >
                            <h3 className="text-2xl font-semibold mb-4 text-blue-300">Live Equipment Utilization</h3>
                            <InteractiveChart
                                chartType="pie"
                                data={equipmentUtilization}
                                config={{
                                    dataKey: 'value',
                                    nameKey: 'name',
                                    colors: ['#3B82F6', '#10B981', '#F59E0B', '#EF4444']
                                }}
                            />
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, x: 20 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.8 }}
                            className="bg-gray-800 p-6 rounded-2xl shadow-2xl border border-gray-700"
                        >
                            <h3 className="text-2xl font-semibold mb-4 text-blue-300">Real-time Mining Locations Performance</h3>
                            <InteractiveChart
                                chartType="bar"
                                data={miningLocations}
                                config={{
                                    xAxis: 'name',
                                    bars: [
                                        { dataKey: 'tons', color: '#3B82F6', name: 'Production (tons)' },
                                        { dataKey: 'efficiency', color: '#10B981', name: 'Efficiency (%)' }
                                    ]
                                }}
                            />
                        </motion.div>
                    </div>
                    <div className="mt-10">
                        <h3 className="text-2xl font-semibold mb-4 text-blue-300">Interactive Live Dashboard</h3>
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8 }}
                            className="bg-gray-800 p-6 rounded-2xl shadow-2xl border border-gray-700"
                        >
                            <div className="flex justify-center space-x-4 mb-4">
                                <Button
                                    variant={activeChart === 'production' ? 'default' : 'outline'}
                                    onClick={() => setActiveChart('production')}
                                >
                                    Production
                                </Button>
                                <Button
                                    variant={activeChart === 'operators' ? 'default' : 'outline'}
                                    onClick={() => setActiveChart('operators')}
                                >
                                    Operator Performance
                                </Button>
                            </div>
                            <AnimatePresence mode="wait">
                                <motion.div
                                    key={activeChart}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -20 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    {activeChart === 'production' && (
                                        <InteractiveChart
                                            chartType="line"
                                            data={productionData}
                                            config={{
                                                xAxis: 'name',
                                                lines: [
                                                    { dataKey: 'production', color: '#3B82F6', name: 'Production (tons)' },
                                                    { dataKey: 'efficiency', color: '#10B981', name: 'Efficiency (%)' },
                                                    { dataKey: 'equipment_utilization', color: '#F59E0B', name: 'Equipment Utilization (%)' }
                                                ]
                                            }}
                                        />
                                    )}
                                    {activeChart === 'operators' && (
                                        <InteractiveChart
                                            chartType="scatter"
                                            data={operatorPerformance}
                                            config={{
                                                xAxis: 'loads',
                                                yAxis: 'efficiency',
                                                xAxisName: 'Number of Loads',
                                                yAxisName: 'Efficiency (%)',
                                                name: 'Operators',
                                                color: '#3B82F6'
                                            }}
                                        />
                                    )}
                                </motion.div>
                            </AnimatePresence>
                        </motion.div>
                    </div>
                </div>
            </section>

            {/* About Section */}
            <section id="about" className="py-20 px-4 bg-gray-800 relative overflow-hidden">
                <AnimatedBackground />
                <div className="container mx-auto relative z-10">
                    <motion.h2
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        className="text-4xl font-bold text-center mb-12 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-400"
                    >
                        About pIT.Software
                    </motion.h2>
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, delay: 0.2 }}
                        className="max-w-3xl mx-auto bg-gray-800 bg-opacity-50 p-8 rounded-2xl shadow-2xl backdrop-blur-lg"
                    >
                        <p className="text-lg text-gray-300 mb-6 leading-relaxed">
                            pIT.Software is at the forefront of mining technology, providing cutting-edge fleet management solutions to optimize your operations. Our AI-driven platform transforms raw data into actionable insights, helping you increase efficiency and reduce costs across your entire mining fleet.
                        </p>
                        <p className="text-lg text-gray-300 mb-6 leading-relaxed">
                            With comprehensive modules for equipment tracking, operator management, load and dump analysis, and real-time performance monitoring, pIT.Software gives you unparalleled control over your mining operations. Our system integrates seamlessly with existing infrastructure, providing a unified view of your entire fleet.
                        </p>
                        <p className="text-lg text-gray-300 leading-relaxed">
                            Join the revolution in mining operations. Choose pIT.Software and unleash the full potential of your fleet.
                        </p>
                    </motion.div>
                </div>
            </section>

            {/* Footer */}
            <footer className="bg-gray-900 text-gray-300 py-12 px-4 relative overflow-hidden">
                <AnimatedBackground />
                <div className="container mx-auto relative z-10">
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-8">
                        <div>
                            <h3 className="text-2xl font-bold text-blue-400 mb-4">pIT.Software</h3>
                            <p className="text-sm">Revolutionizing mining operations with advanced fleet management and data analytics solutions.</p>
                        </div>
                        <div>
                            <h4 className="text-lg font-semibold text-blue-300 mb-4">Quick Links</h4>
                            <ul className="space-y-2">
                                {['Features', 'Analytics', 'About'].map((item) => (
                                    <li key={item}>
                                        <a href={`#${item.toLowerCase()}`} className="hover:text-blue-400 transition-colors duration-300">{item}</a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div>
                            <h4 className="text-lg font-semibold text-blue-300 mb-4">Solutions</h4>
                            <ul className="space-y-2">
                                <li>Fleet Optimization</li>
                                <li>Predictive Maintenance</li>
                                <li>Real-time Tracking</li>
                                <li>Performance Analytics</li>
                            </ul>
                        </div>
                        <div>
                            <h4 className="text-lg font-semibold text-blue-300 mb-4">Contact Us</h4>
                            <ul className="space-y-2">
                                <li className="flex items-center">
                                    <MapPin className="w-5 h-5 mr-2 text-blue-400" />
                                    117/159 Mann St Gosford
                                </li>
                                <li className="flex items-center">
                                    <Phone className="w-5 h-5 mr-2 text-blue-400" />
                                    <a href="tel:0280062033" className="hover:text-blue-400 transition-colors duration-300">02 8006 2033</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="border-t border-gray-800 pt-8 text-center">
                        <p className="mb-4">&copy; 2024 pIT.Software. All rights reserved.</p>
                        <p className="mb-4">
                            <a href="https://lance.name" className="text-blue-400 hover:text-blue-300 transition-colors duration-300">Created by Lance</a>
                        </p>
                    </div>
                </div>
            </footer>

            {/* Floating Action Button */}
            <AnimatePresence>
                {showScrollTop && (
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 20 }}
                        transition={{ duration: 0.3 }}
                        className="fixed bottom-8 right-8 z-50"
                    >
                        <Button
                            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                            className="bg-blue-500 hover:bg-blue-600 text-white p-3 rounded-full shadow-lg transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
                            aria-label="Scroll to top"
                        >
                            <ChevronDown className="w-6 h-6 transform rotate-180" />
                        </Button>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}